// File: calendar.js
import Cumulo from '../modules/cumulo.js';
import tbxHandleExpanders from '../sections/expanders.js';

class CalendarRenderer extends Cumulo {
    onEnter() {
		 tbxHandleExpanders();
    }
}

export default CalendarRenderer;