// File: general-template-3.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';

class GeneralTemplate3Renderer extends Cumulo {
    onEnter() {
    }
}

// Don`t forget to export your renderer
export default GeneralTemplate3Renderer;