// File: landing-page-2-column.js
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleExpanders from "../sections/expanders.js";
import throttle from '../global/throttle.js';
import ScrollMagic from 'scrollmagic';

class LandingPage2ColumnRenderer extends Cumulo {
    onEnter() {
    	$('html').addClass('alt-header');
    	
		tbxHandleExpanders();

		$(window).on('scroll load', throttle(function(e) {
			var windowTop = $(window).scrollTop();

			// handle scrolled header
			if ( windowTop > 880 ) {
				$('.landing-page-scrolled-header-wrap').addClass('scrolled');
			}
			else {
				$('.landing-page-scrolled-header-wrap').removeClass('scrolled');
			}
		}, 100 ));

		// handle scrolled header
		var $listEl, leftPos, newWidth;
	    
	    var $magicLine = $('.bottom-line');

	    $magicLine
	        .width($('.scrolled-header-nav li:first-child').width())
	        .css('left', $('.scrolled-header-nav li:first-child').position().left)
	        .data('origLeft', $magicLine.position().left)
	        .data('origWidth', $magicLine.width());
	        
	    $('.scrolled-header-nav li a').hover(function() {
	        $listEl = $(this);
        	leftPos = $listEl.position().left;
	        newWidth = $listEl.parent().width();
	        $magicLine.stop().animate({
	            left: leftPos,
	            width: newWidth
	        });
	    }, function() {
	        $magicLine.stop().animate({
	            left: $magicLine.data('origLeft'),
	            width: $magicLine.data('origWidth')
	        });
	    });

	    $(window).on('headerClassChange', function() {
			$listEl = $('.scrolled-header-nav li.active');
			leftPos = $listEl.position().left;
	        newWidth = $listEl.width();
	        $magicLine.stop().animate({
	            left: leftPos,
	            width: newWidth
	        });
	        $magicLine
	        .data('origLeft', $listEl.position().left)
	        .data('origWidth', $listEl.width());
	    });

		// handle scrolled header active sections
		var smController = new ScrollMagic.Controller();
		var pageSections = ['#main', '#cs1', '#cs2', '#stats', '#expanders'];

		$.each( pageSections, function( index, el ) {
			var $el = $(el);
			var elMenuClass = '.menu-' + el.replace('#', '');

			if ( $el.length > 0 && $(elMenuClass).length > 0 ) {
				var elDuration = $el.outerHeight();

				var scene = new ScrollMagic.Scene({
					triggerElement: el,
					duration: elDuration,
					triggerHook: 'onCenter'
				}).setClassToggle(elMenuClass, 'active').addTo(smController);

				scene.on('start', function(event) {
					$(window).trigger('headerClassChange');
				});
			}
		} );

		// handle scrolled header jump nav
		$('.scrolled-header-nav a').on('click', function(e) {
			e.preventDefault();

			$('html, body').animate({
	            scrollTop: $( $.attr(this, 'href') ).offset().top - 80
	        }, 1200);
		});
    }
}

export default LandingPage2ColumnRenderer;