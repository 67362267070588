// File: general-template-5.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import tbxHandleExpanders from '../sections/expanders.js';

class GeneralTemplate5Renderer extends Cumulo {
    onEnter() {
    	tbxHandleExpanders();
    }
}

// Don`t forget to export your renderer
export default GeneralTemplate5Renderer;