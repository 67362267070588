// File: hub-academics-location.js
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleFilterPage from '../sections/filter-list.js';
import tbxHandleExpanders from '../sections/expanders.js';

class HubAcademicsLocationRenderer extends Cumulo {
    onEnterCompleted() {
    	$('.side-jump-nav .nav li a').on('click', function(e) {
            e.preventDefault();

            let value = $(this).attr('href');

            $('html, body').animate({
                scrollTop: $(value).offset().top - 130
            }, 800);
        });

        $('.page-jump-nav .mobile-toggle').on('change', function(e) {
            let value = $(this).find('option:selected').val();

            $('html, body').animate({
                scrollTop: $(value).offset().top - 110
            }, 800);
        });

    	tbxHandleFilterPage();
    	tbxHandleExpanders();
    }
}

export default HubAcademicsLocationRenderer;