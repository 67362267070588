import $ from 'jquery';

function tbxHandleSearchOverlay() {
	$('.header-wrap .toggle-search-overlay, .scrolled-header-wrap .toggle-search-overlay').on('click', function(e) {
		e.preventDefault();

		$('html').addClass('mobile-menu-shown');
		$('.cu-part__search-overlay-top').addClass('show');
		$('.cu-part__search-overlay-bottom').addClass('show');

		$('.cu-part__search-overlay-top input[name="s"]').focus();
	});

	$('.close-search-overlay').on('click', function(e) {
		e.preventDefault();
		
		$('html').removeClass('mobile-menu-shown');
		$('.cu-part__search-overlay-top').removeClass('show');
		$('.cu-part__search-overlay-bottom').removeClass('show');

		// clear selections
		$('.cu-part__search-overlay-top input[name="s"]').val('');
	});

	$('.cu-part__search-overlay-top .search-form').on('submit', function() {
		$('html').removeClass('mobile-menu-shown');
		$('.cu-part__search-overlay-top').removeClass('show');
		$('.cu-part__search-overlay-bottom').removeClass('show');
	});

	$('.cu-part__search-overlay-bottom a').on('click', function(e) {
		$('html').removeClass('mobile-menu-shown');
		$('.cu-part__search-overlay-top').removeClass('show');
		$('.cu-part__search-overlay-bottom').removeClass('show');

		// clear selections
		$('.cu-part__search-overlay-top input[name="s"]').val('');
	});
}

export default tbxHandleSearchOverlay;