// File: experience.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use( [ Navigation, Pagination ] );
import $ from "jquery";

class ExperienceRenderer extends Cumulo {
    onEnter() {
		$('.cu-section__main-content .main-jump-nav a').on('click', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 130
            }, 800);
        });

        // handle gallery swiper
        const experienceImageSwiper = new Swiper('.image-swiper', {
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.image-swiper-next',
                prevEl: '.image-swiper-prev'
            },
            pagination: {
                el: '.image-swiper-pagination'
            }
        });
    }
}

// Don`t forget to export your renderer
export default ExperienceRenderer;