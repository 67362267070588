function tbxHandleScrollProgressBar() {
	var h = document.documentElement,
	  b = document.body,
	  st = 'scrollTop',
	  sh = 'scrollHeight',
	  progress = document.querySelector('.cu-section__progress-bar'),
	  scroll;

	document.addEventListener('scroll', function() {
		scroll = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
		progress.style.setProperty('--scroll', scroll + '%');
	});
}

export default tbxHandleScrollProgressBar;