import $ from 'jquery';

function tbxHandleExpanders() {
	$('.cu-part__expanders .expander .expander-header').on('click', function(e) {
		e.preventDefault();

		$(this).toggleClass('active').parent().find('.expander-body').slideToggle(400);
	});
}

export default tbxHandleExpanders;