/*
TBX Module - Video Overlay
- use the class ".tbx-module__video-overlay-toggle" on the element you want to toggle the overlay
- add a data attribute to the element named "data-video-url" and add your video url there
- you must call the PHP function "tbx_video_overlay()" somewhere on your page (footer.php is usually best, so it's global)
- this code will add a "overlay-shown" body class that can be used to stop background scrolling
*/

import $ from 'jquery';

function tbxHandleVideoOverlay() {
    $('.tbx-module__video-overlay-toggle').on('click', function(e) {
        e.preventDefault();
        if ( $('.tbx-module__video-overlay #tbx-overlay-player').attr('src') !== $(this).attr('data-video-url') ) {
            // Grab data-video-url and input into iframe
            $('.tbx-module__video-overlay #tbx-overlay-player').attr('src', $(this).attr('data-video-url'));

            // Open overlay
            $('body').addClass('overlay-shown');
            $('.tbx-module__video-overlay').addClass('open');
            $('.tbx-module__video-overlay .video-overlay').addClass('open');
        }
        else {
            // Open overlay
            $('body').addClass('overlay-shown');
            $('.tbx-module__video-overlay').addClass('open');
            $('.tbx-module__video-overlay .video-overlay').addClass('open');
        }
    });

    $('.tbx-module__video-overlay .video-overlay-close').on('click', function(e) {
        e.preventDefault();

        // Close Overlay
        $('body').removeClass('overlay-shown');
        $('.tbx-module__video-overlay').removeClass('open');
        $('.tbx-module__video-overlay .video-overlay').removeClass('open');

        $('.tbx-module__video-overlay #tbx-overlay-player').attr('src', '');
    });
}

export default tbxHandleVideoOverlay;