import $ from 'jquery';

function tbxBindCustomEvents() {
    $('#wpforms-37188 .wpforms-field-container .wpforms-page-next').on('click',function(e){
        gtag('event', 'Proceed To Step 2', {
            'event_category': 'Lead Form (Short)',
            'event_label':'Academic Program',
            'value': 1
        });
        // ga('send', {
        //     hitType: 'event',
        //     eventCategory: 'Lead Form (Short)',
        //     eventAction: 'Proceed To Step 2',
        //     eventLabel: 'Academic Program'
        //   });
        console.log('Proceed To Step 2 - Lead Form (Short)');
    });
    $('#wpforms-37188 .wpforms-submit-container .wpforms-submit').on('click',function(){
        gtag('event', 'Submit (Short)', {
            'event_category': 'Lead Form (Short)',
            'event_label':'Academic Program',
            'value': 1
        });
        // ga('send', {
        //     hitType: 'event',
        //     eventCategory: 'Lead Form (Short)',
        //     eventAction: 'Submit',
        //     eventLabel: 'Academic Program'
        //   });
        console.log('Submit - Lead Form (Short)');
    });
}

export default tbxBindCustomEvents;