// File: hub-admissions.js
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleNewsFeed from '../sections/news-feed.js';

class HubAdmissionsRenderer extends Cumulo {
    onEnterCompleted() {
        $('.side-jump-nav .nav li a').on('click', function(e) {
            e.preventDefault();

            let value = $(this).attr('href');

            $('html, body').animate({
                scrollTop: $(value).offset().top - 130
            }, 800);
        });

        $('.page-jump-nav .mobile-toggle').on('change', function(e) {
            let value = $(this).find('option:selected').val();

            $('html, body').animate({
                scrollTop: $(value).offset().top - 110
            }, 800);
        });

		tbxHandleNewsFeed();
    }
}

export default HubAdmissionsRenderer;