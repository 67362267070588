// File: program-ind-v3.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import tbxHandleExpanders from "../sections/expanders.js";

class ProgramIndV3Renderer extends Cumulo {
    onEnter() {
		tbxHandleExpanders();
    }
}

// Don`t forget to export your renderer
export default ProgramIndV3Renderer;