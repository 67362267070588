// File: traditional-landing.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import tbxHandleNewsFeed from '../sections/news-feed.js';

class TraditionalLandingRenderer extends Cumulo {
    onEnter() {
        tbxHandleNewsFeed();
    }
}

// Don`t forget to export your renderer
export default TraditionalLandingRenderer;