// vendor
require('objectFitPolyfill');
require('intersection-observer');
require('iframe-resizer');
import $ from 'jquery';

import throttle from './js/global/throttle.js';
import tbxHandleScrollLoadEvent from './js/global/aos.js';
import tbxBindCustomEvents from './js/global/ga-custom-events.js';
import tbxMobileMenuClick from './js/sections/mobile-menu.js';
import tbxHandleVideoOverlay from './js/global/video-overlay.js';
import tbxHandleNotices from './js/modules/notices.js';
import tbxHandleScrollProgressBar from './js/sections/progress-bar.js';
import tbxHandleScrolledHeader from './js/sections/scrolled-header.js';
import tbxHandleMegaNavs from './js/sections/mega-navs.js';
import tbxHandleSearchOverlay from './js/sections/search-overlay.js';
import tbxHandleExploreNav from  './js/sections/explore-nav.js';
import tbxHandleStatsCountup from './js/sections/stats.js';
import tbxHandleColorStatsCountup from './js/sections/colors.js';

// renderers
import HomeRenderer from './js/pages/home.js';
import FormTemplate1Renderer from './js/pages/form-template-1.js';
import FormTemplate2Renderer from './js/pages/form-template-2.js';
import ResourcesRenderer from './js/pages/resources.js';
import TraditionalLandingRenderer from './js/pages/traditional-landing.js';
import AdmissionsRenderer from './js/pages/admissions.js';
import NextStepsRenderer from './js/pages/next-steps.js';
import NextStepsIndRenderer from './js/pages/next-steps-ind.js';
import GeneralTemplate1Renderer from './js/pages/general-template-1.js';
import GeneralTemplate2Renderer from './js/pages/general-template-2.js';
import GeneralTemplate3Renderer from './js/pages/general-template-3.js';
import GeneralTemplate4Renderer from './js/pages/general-template-4.js';
import GeneralTemplate5Renderer from './js/pages/general-template-5.js';
import GeneralTemplate6Renderer from './js/pages/general-template-6.js';
import GeneralTemplate7Renderer from './js/pages/general-template-7.js';
import GeneralTemplate8Renderer from './js/pages/general-template-8.js';
import GeneralTemplate9Renderer from './js/pages/general-template-9.js';
import PlanAVisitRenderer from './js/pages/plan-a-visit.js';
import AccreditationRenderer from './js/pages/accreditation.js';
import TuitionFeesRenderer from './js/pages/tuition-fees.js';
import TuitionFeesRepeaterRenderer from './js/pages/tuition-fees-repeater.js';
import ExperienceRenderer from './js/pages/experience.js';
import AcademicsRenderer from './js/pages/academics.js';
import FacultyRenderer from './js/pages/faculty.js';
import FacultyIndRenderer from './js/pages/faculty-ind.js';
import PLORenderer from './js/pages/program-learning-outcomes.js';
import PLOIndRenderer from './js/pages/plo-ind.js';
import DivisionsRenderer from './js/pages/divisions.js';
import DivisionIndRenderer from './js/pages/division-ind.js';
import ProgramsRenderer from './js/pages/programs.js';
import ProgramIndV1Renderer from './js/pages/program-ind-v1.js';
import ProgramIndV2Renderer from './js/pages/program-ind-v2.js';
import ProgramIndV3Renderer from './js/pages/program-ind-v3.js';
import EmbedRenderer from './js/pages/embed.js';
import ExpandCollapseRenderer from './js/pages/expand-collapse.js';
import RentableVenuesRenderer from './js/pages/rentable-venues.js';
import SearchRenderer from './js/pages/search.js';
import NewsEventsRenderer from './js/pages/news-events.js';
import NewsRenderer from './js/pages/news.js';
import EventsRenderer from './js/pages/events.js';
import BlogsRenderer from './js/pages/blogs.js';
import BlogTextRenderer from './js/pages/blog-text.js';
import PostTextRenderer from './js/pages/post-text.js';
import PostVideoRenderer from './js/pages/post-video.js';
import EventIndRenderer from './js/pages/event-ind.js';
import AdultLandingRenderer from './js/pages/adult-landing.js';
import GraduateLandingRenderer from './js/pages/graduate-landing.js';
import SeminaryLandingRenderer from './js/pages/seminary-landing.js';
import SeminaryAcademicsRenderer from './js/pages/seminary-academics.js';
import SeminaryNextStepsIndRenderer from './js/pages/seminary-next-steps-ind.js';
import QuickLinksAdmissionsRenderer from './js/pages/quick-links-admissions.js';
import HubAdmissionsRenderer from './js/pages/hub-admissions.js';
import HubAcademicsRenderer from './js/pages/hub-academics.js';
import HubFinancialAidRenderer from './js/pages/hub-financial-aid.js';
import HubAcademicsLocationRenderer from './js/pages/hub-academics-location.js';

import LandingPageRenderer from './js/pages/landing-page.js';
import LandingPage2ColumnRenderer from './js/pages/landing-page-2-column.js';
import LandingPageContentOfferRenderer from './js/pages/landing-page-content-offer.js';
import LandingPageGivingRenderer from './js/pages/landing-page-giving.js';

import CalendarRenderer from './js/pages/calendar.js';
import CalendarIndRenderer from './js/pages/calendar-ind.js';

import './js/components/sample.js';
import './js/components/newsandevents.js';
import './js/components/rotator.js';
import './js/components/degreesprograms.js';
import './js/components/list-accordion.js';
import './js/components/filter-list.js';




	var pages = {
		"home": HomeRenderer,
		"form-template-1": FormTemplate1Renderer,
		"form-template-2": FormTemplate2Renderer,
		"resources": ResourcesRenderer,
		"traditional-landing": TraditionalLandingRenderer,
		"admissions": AdmissionsRenderer,
		"next-steps": NextStepsRenderer,
		"next-steps-ind": NextStepsIndRenderer,
		"general-template-1": GeneralTemplate1Renderer,
		"general-template-2": GeneralTemplate2Renderer,
		"general-template-3": GeneralTemplate3Renderer,
		"general-template-4": GeneralTemplate4Renderer,
		"general-template-5": GeneralTemplate5Renderer,
		"general-template-6": GeneralTemplate6Renderer,
		"general-template-7": GeneralTemplate7Renderer,
		"general-template-8": GeneralTemplate8Renderer,
		"general-template-9": GeneralTemplate9Renderer,
		"plan-a-visit": PlanAVisitRenderer,
		"accreditation": AccreditationRenderer,
		"tuition-fees": TuitionFeesRenderer,
		"tuition-fees-repeater": TuitionFeesRepeaterRenderer,
		"experience": ExperienceRenderer,
		"academics": AcademicsRenderer,
		"faculty": FacultyRenderer,
		"faculty-single": FacultyIndRenderer,
		"program-learning-outcomes": PLORenderer,
		"plo-single": PLOIndRenderer,
		"divisions": DivisionsRenderer,
		"division-single": DivisionIndRenderer,
		"programs": ProgramsRenderer,
		"program-ind-v1": ProgramIndV1Renderer,
		"program-ind-v2": ProgramIndV2Renderer,
		"program-ind-v3": ProgramIndV3Renderer,
		'embed': EmbedRenderer,
		"expand-collapse": ExpandCollapseRenderer,
		"rentable-venues": RentableVenuesRenderer,
		"search": SearchRenderer,
		"news-events": NewsEventsRenderer,
		"news": NewsRenderer,
		"events": EventsRenderer,
		"blogs": BlogsRenderer,
		"blog-text": BlogTextRenderer,
		"post-text": PostTextRenderer,
		"post-video": PostVideoRenderer,
		"event-single": EventIndRenderer,
		"adult-landing": AdultLandingRenderer,
		"graduate-landing": GraduateLandingRenderer,
		"seminary-landing": SeminaryLandingRenderer,
		"seminary-academics": SeminaryAcademicsRenderer,
		"seminary-next-steps-ind": SeminaryNextStepsIndRenderer,
		"quick-links-admissions": QuickLinksAdmissionsRenderer,
		"landing-page": LandingPageRenderer,
		"landing-page-2-column": LandingPage2ColumnRenderer,
		"landing-page-content-offer": LandingPageContentOfferRenderer,
		"landing-page-giving": LandingPageGivingRenderer,
		"calendar": CalendarRenderer,
		"calendar-ind": CalendarIndRenderer,
		"hub-admissions": HubAdmissionsRenderer,
		"hub-academics": HubAcademicsRenderer,
		"hub-financial-aid": HubFinancialAidRenderer,
		"hub-academics-location": HubAcademicsLocationRenderer
	};

	for (let [key, value] of Object.entries(pages)) 
	{
		if ( ( typeof $(`.cumulo-${key}`) != 'undefined' && $(`.cumulo-${key}`).length ) 
		) {
			console.log(key);
			let instance = new value();
			instance.onEnter();
			instance.onEnterCompleted();
		}
	}

// // Query admin bar links and detach them from Cumulo transitions
// const adminBarLinks = document.querySelectorAll('#wpadminbar a');
// // H.detach(adminBarLinks);

// // detach all links from highway that activate a sub menu
// const subMenuLinks = document.querySelectorAll('.mobile-menu-nav li.menu-item-has-children > a');
// // H.detach(subMenuLinks);

// // detach mega nav links
// const megaNavLinks = document.querySelectorAll('.toggle-academic-programs-meganav a, .toggle-admissions-meganav a, .toggle-tuition-meganav a, .toggle-experience-meganav a');
// // H.detach(megaNavLinks);

// // disable landing page links
// const lpLinks = document.querySelectorAll('.landing-page-scrolled-header-wrap a, .mobile-menu.landing-page a');
// // H.detach(lpLinks);

// // disable calendar links
// const tecCalendarLinks = document.querySelectorAll('.tribe-events a');
// // H.detach(tecCalendarLinks);

// Handle the mobile menu click
tbxMobileMenuClick();

// Handle scroll load effect on initial load
$(window).on('scroll load', throttle(function() {
	tbxHandleScrollLoadEvent();
	tbxHandleScrolledHeader();
}, 100));

// object fit polyfill
objectFitPolyfill();

// handle notice module
tbxHandleNotices();

// handle video overlay on load
tbxHandleVideoOverlay();

// handle scroll progress bar
tbxHandleScrollProgressBar();

// handle mega navs
tbxHandleMegaNavs();

// handle search overlay
tbxHandleSearchOverlay();

// handle explore nav, where it exists
tbxHandleExploreNav();

// handle stats section, where it exists
tbxHandleStatsCountup();

// handle colors section, where it exists
tbxHandleColorStatsCountup();

// bind the GA events 
tbxBindCustomEvents();

// handle iframe resizers
const iframes = iFrameResize( {}, '#cu-form' );


// handle AJAX interactions
$( document ).ajaxStart(function() {
    $('html').addClass('loading-overlay-shown');
    $('.cu-section__loading-overlay').fadeIn();
});

$( document ).ajaxComplete(function() {
    $('html').removeClass('loading-overlay-shown');
    $('.cu-section__loading-overlay').fadeOut();

    // const newItemLinks = document.querySelectorAll('.filter-list-item a');
    // if ( newItemLinks !== null ) {
    // 	H.attach(newItemLinks);
    // }
});

// H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
// 	$('html').removeClass('alt-header');
// });

// handle navigation to new pages
// H.on('NAVIGATE_END', ({ to, trigger, location }) => {
	// manage new scripts and styles
	// manageStyles(to);
    // manageScripts(to);

	// const quicklink = '';

	// handle removing scroll/load events
	$(window).off('scroll load');

	// Handle scroll load effect on initial load
	$(window).on('scroll load', throttle(function() {
		tbxHandleScrollLoadEvent();
		tbxHandleScrolledHeader();
	}, 100));
	
	// handle "load" event on new pages
	tbxHandleScrollLoadEvent();

	// handle explore nav, where it exists
	tbxHandleExploreNav();

	// handle stats section, where it exists
	tbxHandleStatsCountup();

	// handle colors section, where it exists
	tbxHandleColorStatsCountup();

	// object fit polyfill
	objectFitPolyfill();

	// handle scroll progress bar
	tbxHandleScrollProgressBar();

	// handle video overlay on load
	tbxHandleVideoOverlay();

	// handle iframe resizers

	// handle quicklink
	// listen({
	// 	el: to.view,
	// 	ignores: [
	// 		uri => uri.includes('#'),
	// 		( uri, elem ) => elem.hasAttribute('data-router-disabled'),
	// 		( uri, elem ) => elem.hasAttribute('target')
	// 	]
	// });
	
	// Analytics
	// if (typeof gtag !== 'undefined') {
	// 	gtag('config', 'GTM-WDDDMK', {
	// 		'page_path': location.pathname,
	// 		'page_title': to.page.title,
	// 		'page_location': location.href
	// 	});
	// }

	// Query admin bar links, and new pages admin bar links
	// const adminBarLinks = document.querySelectorAll('#wpadminbar a');
	// const newAdminBarLinks = to.page.body.querySelectorAll('#wpadminbar a');

	// // Replace every admin bar link's href value with new value
	// for (let i = 0; i < newAdminBarLinks.length; i++) {
	// 	adminBarLinks[i].href = newAdminBarLinks[i].href;
	// }

	// Detach admin bar links from Cumulo transitions
	// H.detach(adminBarLinks);
// });

