// File: post-text.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class PostTextRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');
    }
}

// Don`t forget to export your renderer
export default PostTextRenderer;