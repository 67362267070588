// File: hub-academics.js
import Cumulo from '../modules/cumulo.js';
import tbxHandleFilterPage from '../sections/filter-list.js';

class HubAcademicsRenderer extends Cumulo {
    onEnterCompleted() {
    	tbxHandleFilterPage();
    }
}

export default HubAcademicsRenderer;