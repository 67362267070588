import $ from "jquery";
import throttle from '../global/throttle.js';
import { tbxIsScrolledIntoView } from '../global/aos.js';
import { CountUp } from 'countup.js';

function tbxHandleStatsCountup() {
	if ( $('.cu-section__stats').length > 0 ) {
		$(window).on('scroll load', throttle( function() {
			$('.cu-section__stats .stat .count-to').each(function(index, element) {
				if ( ! $(this).hasClass('tbx-animated-siv-ft') && tbxIsScrolledIntoView( element ) ) {
					var countUp = new CountUp( element, $(this).data('count-to') );
					if ( !countUp.error ) {
						countUp.start();
					} 
					else {
					  console.error(countUp.error);
					}
				}
			});
		}, 100 ));
	}
}

export default tbxHandleStatsCountup;