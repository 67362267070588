// File: form-template-2.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class FormTemplate2Renderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        const scripts = document.querySelectorAll('script');

        for ( const script of scripts ) {
            let code = script.innerText;

            if ( code.length !== 0 && ( code.indexOf('gravityform') !== -1 || code.indexOf('gform') !== -1 ) ) {
                Function(code)();
            }
        }
    }
}

// Don`t forget to export your renderer
export default FormTemplate2Renderer;