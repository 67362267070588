// File: news-events.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleNewsFeed from '../sections/news-feed.js';

class NewsEventsRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        // handle jump nav
        $('.jump-nav .toggle').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.jump-nav .nav').toggle();
        });

        $('body').on('click', function() {
            $('.jump-nav .nav').hide();
        });

        $('.jump-nav .nav li').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            let text = $(this).text();
            let value = $(this).data('option-value');

            $('.jump-nav .toggle').text(text);

            $('.jump-nav .nav').hide();

            $('html, body').animate({
                scrollTop: $(value).offset().top - 130
            }, 800);
        });

        $('.jump-nav .mobile-toggle').on('change', function(e) {
            let value = $(this).find('option:selected').val();

            $('html, body').animate({
                scrollTop: $(value).offset().top - 110
            }, 800);
        });

        $('#featured-news .pagination .post').on('mouseenter', function(e) {
            $('#featured-news .pagination .post.active').removeClass('active');
            $(this).addClass('active');

            let postImage = $(this).data('image-url');
            $('#featured-news .image').css('backgroundImage', 'url('+postImage+')');
        });

        tbxHandleNewsFeed();
    }
}

// Don`t forget to export your renderer
export default NewsEventsRenderer;