// File: general-template-6.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';

class GeneralTemplate6Renderer extends Cumulo {
    onEnter() {
    }
}

// Don`t forget to export your renderer
export default GeneralTemplate6Renderer;