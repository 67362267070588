// File: calendar-ind.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class CalendarIndRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');
    }
}

// Don`t forget to export your renderer
export default CalendarIndRenderer;