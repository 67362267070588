// File: admissions.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from "jquery";
import tbxHandleExpanders from "../sections/expanders.js";

class AdmissionsRenderer extends Cumulo {
    onEnter() {
		tbxHandleExpanders();

		$('.cu-section__main-content .main-jump-nav a').on('click', function(e) {
			e.preventDefault();

			$('html, body').animate({
	            scrollTop: $( $.attr(this, 'href') ).offset().top - 130
	        }, 800);
		});
    }
}

// Don`t forget to export your renderer
export default AdmissionsRenderer;