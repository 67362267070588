// File: faculty.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleFilterPage from '../sections/filter-list.js';

class FacultyRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        tbxHandleFilterPage();
    }
}

// Don`t forget to export your renderer
export default FacultyRenderer;