// File: plan-a-vist.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';

class PlanAVisitRenderer extends Cumulo {
    onEnter() {
    }
}

// Don`t forget to export your renderer
export default PlanAVisitRenderer;