// File: search.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class SearchRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        $('.search-categories button.toggle-category').on('click', function(e) {
            e.preventDefault();

            var category = $(this).data('category');

            $('button.toggle-category.active').removeClass('active');
            $(this).addClass('active');

            $('.tab').hide();
            $('.tab[data-tab="'+ category +'"]').show();
        });

        $('.search-results-wrap a.arrow-btn').on('click', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: 0
            }, 800);
        });
    }
}

// Don`t forget to export your renderer
export default SearchRenderer;