/*------------------------------------------
    MOBILE MENU INTERACTIONS
============================================*/
import $ from 'jquery';

function tbxMobileMenuClick() {
    let $mobileMenuToggle = $('.mobile-menu-toggle');
    let $mobileMenu = $('.mobile-menu');

    $mobileMenuToggle.on('click', function(e) {
        e.preventDefault();

        $('html').toggleClass('mobile-menu-shown');
        $mobileMenu.toggleClass('active');

        $(this).toggleClass('active');

        // close all mobile menus
        $('.mobile-sub-menu').removeClass('active');
    });

    // handle overlay menu dropdowns
    $mobileMenu.find('li.menu-item-has-children > a').on('click', function(e) {
        e.preventDefault();

        if ( $(this).parent().hasClass('active') ) {
            $(this).parent().removeClass('active').find('.sub-menu').slideUp(400);
        } else {
            $(this).parents('ul').find('li.active').removeClass('active').find('.sub-menu').slideUp(400);
            $(this).parent().addClass('active').find('.sub-menu').slideDown(400);
        }
    });

    // close menus on link click
    $mobileMenu.find('a').on('click', function(e) {
        if ( ! $(this).parent().hasClass('menu-item-has-children') ) {
            $('.mobile-menu.active').removeClass('active');
            $('html').removeClass('mobile-menu-shown');
            $('.mobile-menu-toggle').removeClass('active');
        }
    });

    $('.mobile-menu.landing-page').find('a').on('click', function(e) {
        $('.mobile-menu.landing-page').removeClass('active');
        $('html').removeClass('mobile-menu-shown');
        $('.mobile-menu-toggle').removeClass('active');
    });
}

export default tbxMobileMenuClick;