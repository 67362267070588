import Swiper, { Navigation, Scrollbar } from 'swiper';

export default function tbxHandleNewsFeed() {
	const sections = document.querySelectorAll('.cu-section__news-feed');

	if ( sections !== null && sections.length > 0 ) {
		sections.forEach( section => {
			new Swiper( section.querySelector('.swiper-container'), {
				modules: [ Navigation, Scrollbar ],
				slidesPerView: 'auto',
				spaceBetween: 25,
				navigation: {
					prevEl: section.querySelector('.swiper-button-prev'),
					nextEl: section.querySelector('.swiper-button-next')
				},
				scrollbar: {
	                el: section.querySelector('.swiper-scrollbar'),
	                draggable: true,
	                dragSize: 180
	            },
			});
		});
	}
}