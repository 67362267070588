// File: event-ind.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class EventIndRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');
    }
}

// Don`t forget to export your renderer
export default EventIndRenderer;