import $ from "jquery";
import Isotope from "isotope-layout";

class FilterList {
    constructor(element) {
        this.element = $(element);
        this.filtered = false;
        this.excludedPosts = '';
        this.filters = {};
        this.search = '';
        this.pagination = 1;
        this.gridEl = this.element.find('.cu-section__filter-list.table .table-body').get(0) || this.element.find('.cu-section__filter-list').get(0);
        this.grid = null;
        this.initGrid();
    }

    initGrid() {
        if (this.gridEl !== null) {
            const options = {
                itemSelector: '.filter-list-item',
                layoutMode: this.gridEl.parentNode.parentNode.classList.contains('table') ? 'vertical' : 'fitRows',
            };
            if (options.layoutMode === 'fitRows') {
                options.fitRows = { gutter: 30 };
            }
            this.grid = new Isotope(this.gridEl, options);
        }
    }

    handleAJAX() {
        if (this.filtered) {
            this.excludedPosts = '';
        }

        const data = {
            action: 'cumulo_handle_page_filter',
            filter_list: this.element.find('.cu-section__filter-list').data('filter-list'),
            is_table: this.element.find('.cu-section__filter-list').hasClass('table'),
            filters: JSON.stringify(this.filters),
            search: this.search,
            paged: this.pagination,
            excluded: this.excludedPosts
        };


        console.log(data);
        $.ajax({
            url: tbx_ajax_url,
            data: data,
            success: (response, status, request) => {
                console.log(response);
                this.element.find('.no-posts').remove();
                this.element.find('.view-more').removeClass('disabled');

                if (this.pagination === 1) {
                    this.grid.remove(this.grid.getItemElements());
                }

                let $container = this.element.find('.cu-section__filter-list.table .table-body').length
                    ? this.element.find('.cu-section__filter-list.table .table-body')
                    : this.element.find('.cu-section__filter-list');

                if (response.indexOf('no-posts') >= 0) {
                    $container.append(response);
                    this.grid.layout();
                    $container.css('height', 'auto');
                } else {
                    let $items = $(response);
                    $container.append($items);
                    this.grid.appended($items);
                    this.grid.layout();
                }

                let $elements = this.grid.getItemElements();
                if ($elements.length > 0 && $elements[$elements.length - 1].classList.contains('end')) {
                    this.element.find('.view-more').addClass('disabled').text('No More Posts');
                }
            },
            error: (request, status, error) => {
                console.log(error);
            }
        });
    }

    attachRemoveFilterEvents() {
        this.element.find('.cu-section__filter-bar .active-filters').on('click', '.remove-active-filter', (e) => {
            e.preventDefault();
            const $target = $(e.target);
            const clickedTermSlug = $target.data('filter-term');
            const clickedTaxonomy = $target.data('filter-taxonomy');

            // Update desktop view
            this.element.find(`#${clickedTaxonomy} input[id="${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
            
            // Update mobile view
            this.element.find(`.cu-section__filter-bar-overlay input[id="mobile-${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
            
            delete this.filters[clickedTaxonomy][clickedTermSlug];
            $target.remove();

            this.activateClearFilters();
            this.createMobileActiveFilters(); // Update mobile active filters
            this.handleAJAX();
        });

        const $activeFilters = this.element.find('.cu-section__filter-bar .active-filters');
        $activeFilters.toggleClass('hidden', $activeFilters.is(':empty'));
    }

    attachMobileRemoveFilterEvents() {
        this.element.find('.cu-section__mobile-filter-bar .active-filters').on('click', '.remove-active-filter', (e) => {
            e.preventDefault();
            const $target = $(e.target);
            const clickedTermSlug = $target.data('filter-term');
            const clickedTaxonomy = $target.data('filter-taxonomy');

            // Update mobile view
            this.element.find(`.cu-section__filter-bar-overlay input[id="mobile-${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
            
            // Update desktop view
            this.element.find(`#${clickedTaxonomy} input[id="${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
            
            delete this.filters[clickedTaxonomy][clickedTermSlug];
            $target.remove();

            this.createActiveFilters(); // Update desktop active filters
            this.handleAJAX();
        });

        const $activeFiltersWrap = this.element.find('.cu-section__mobile-filter-bar .active-filters-wrap');
        $activeFiltersWrap.toggleClass('hidden', this.element.find('.cu-section__mobile-filter-bar .active-filters').is(':empty'));
    }

    createActiveFilters() {
        const $activeFilters = this.element.find('.cu-section__filter-bar .active-filters').empty();

        Object.entries(this.filters).forEach(([filter, terms]) => {
            if (filter !== 'buckets' && filter !== 'blog-bucket') {
                Object.entries(terms).forEach(([slug, name]) => {
                    $activeFilters.append(`<button type="button" class="remove-active-filter" data-filter-taxonomy="${filter}" data-filter-term="${slug}">${name}</button>`);
                });
            }
        });

        this.attachRemoveFilterEvents();
    }

    createMobileActiveFilters() {
        const $mobileActiveFilters = this.element.find('.cu-section__mobile-filter-bar .active-filters').empty();

        Object.entries(this.filters).forEach(([filter, terms]) => {
            if (filter !== 'buckets' && filter !== 'blog-bucket') {
                Object.entries(terms).forEach(([slug, name]) => {
                    $mobileActiveFilters.append(`<button type="button" class="remove-active-filter" data-filter-taxonomy="${filter}" data-filter-term="${slug}">${name}</button>`);
                });
            }
        });

        this.attachMobileRemoveFilterEvents();
    }

    activateClearFilters() {
        const shouldActivate = (this.search !== '' && this.search !== 'SEARCH...') || 
                               this.element.find('.cu-section__filter-bar .filter-list input[type="checkbox"]:checked').length !== 0;
        this.element.find('.cu-section__filter-bar .clear-filters').toggleClass('hidden', !shouldActivate);
    }

    createPageFiltersObject() {
        const pageFilterList = this.element.data('filter-list');
        const pageBucketSlug = this.element.data('filter-bucket-slug');
        const pageBucketName = this.element.data('filter-bucket-name');

        if (pageBucketSlug !== '' && pageBucketName !== '') {
            const bucketType = pageFilterList === 'blog' ? 'blog-bucket' : 'buckets';
            this.filters[bucketType] = { [pageBucketSlug]: pageBucketName };
        }

        this.element.find('.cu-section__filter-bar .filter, .cu-section__filter-bar .inline-filter').each((_, el) => {
            const taxonomy = $(el).data('taxonomy');
            this.filters[taxonomy] = {};
        });
    }

    readExistingActiveFilters() {
        this.element.find('.cu-section__filter-bar .active-filters .remove-active-filter').each((_, el) => {
            const $el = $(el);
            const filterTaxonomy = $el.data('filter-taxonomy');
            const filterTerm = $el.data('filter-term');
            const filterName = $el.text();

            if (!this.filters[filterTaxonomy]) {
                this.filters[filterTaxonomy] = {};
            }
            this.filters[filterTaxonomy][filterTerm] = filterName;
        });

        this.element.find('.cu-section__filter-bar .filter-list input[type="checkbox"]:checked, .cu-section__filter-bar .inline-filter input[type="radio"]:checked').each((_, el) => {
            const $el = $(el);
            const filterTaxonomy = $el.attr('name');
            const filterTerm = $el.val();
            const filterName = $el.data('term-name');

            if (!this.filters[filterTaxonomy]) {
                this.filters[filterTaxonomy] = {};
            }
            this.filters[filterTaxonomy][filterTerm] = filterName;
        });

        const existingSearch = this.element.find('#filter-search-value').val();
        if (existingSearch && existingSearch !== '') {
            this.search = existingSearch;
        }
    }
}

function handleFilterPage(filterList) {
    const $element = $(filterList.element);
    const gridEl = $element.find('.cu-section__filter-list.table .table-body').get(0) || $element.find('.cu-section__filter-list').get(0);

    if (gridEl !== null) {
        if (gridEl.parentNode.parentNode.classList.contains('table')) {
            filterList.grid = new Isotope(gridEl, {
                itemSelector: '.filter-list-item',
                layoutMode: 'vertical'
            });
        } else {
            filterList.grid = new Isotope(gridEl, {
                itemSelector: '.filter-list-item',
                layoutMode: 'fitRows',
                fitRows: {
                    gutter: 30
                }
            });
        }
    }

    // get all taxonomies on load
    filterList.createPageFiltersObject();

    // Read existing active filters from markup
    filterList.readExistingActiveFilters();

    // Create active filters based on existing filters
    filterList.createActiveFilters();
    filterList.createMobileActiveFilters();

    // handle inline filter click
    $element.find('.cu-section__filter-bar .inline-filter input[type="radio"]').on('change', function(e) {
        e.preventDefault();

        let clickedFilter = $(this).attr('name');
        let inputTerm = $(this).val();
        let inputText = $(this).data('term-name');

        filterList.filters[clickedFilter] = {};
        filterList.filters[clickedFilter][inputTerm] = inputText;

        // hide filter box
        $element.find('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
        $element.find('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

        // reset page
        filterList.pagination = 1;

        // set page filtered so we don't exclude posts anymore
        filterList.filtered = true;

        filterList.createActiveFilters();
        filterList.activateClearFilters();
        filterList.handleAJAX();
    });

    // show filter overlay on filter toggle click
    $element.find('.cu-section__filter-bar .filter-toggle').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let clickedFilter = $(this).parent().data('taxonomy');

        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $element.find('#' + clickedFilter).removeClass('shown').hide();
        } else {
            $element.find('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
            $element.find('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();
            $(this).addClass('active');
            $element.find('#' + clickedFilter).addClass('shown').show();
        }
    });

    // handle filter button click
    $element.find('.cu-section__filter-bar .filter-selections').on('click', function(e) {
        e.preventDefault();

        let clickedTaxonomy = $(this).data('taxonomy');

        // reset taxonomy current filters and redo them based on selections
        filterList.filters[clickedTaxonomy] = {};

        $element.find(`input[name="${clickedTaxonomy}"]:checked`).each(function() {
            let inputTerm = $(this).val();
            let inputText = $(this).data('term-name');

            filterList.filters[clickedTaxonomy][inputTerm] = inputText;

            // Update mobile overlay checkboxes
            filterList.element.find(`.cu-section__filter-bar-overlay input[id="mobile-${clickedTaxonomy}-${inputTerm}"]`).prop('checked', true);
        });

        // Uncheck mobile overlay checkboxes that are not checked in desktop
        filterList.element.find(`.cu-section__filter-bar-overlay input[name="${clickedTaxonomy}"]`).each(function() {
            let mobileTerm = $(this).val();
            if (!filterList.element.find(`#${clickedTaxonomy} input[id="${clickedTaxonomy}-${mobileTerm}"]`).prop('checked')) {
                $(this).prop('checked', false);
            }
        });

        // hide filter box
        $element.find('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
        $element.find('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

        // reset page
        filterList.pagination = 1;

        // set page filtered so we don't exclude posts anymore
        filterList.filtered = true;

        filterList.createActiveFilters();
        filterList.createMobileActiveFilters(); // Update mobile active filters
        filterList.activateClearFilters();
        filterList.handleAJAX();
    });

    $element.find('.cu-section__filter-bar .search').on('submit', function(e) {
        e.preventDefault();

        filterList.search = $element.find('#filter-search-value').val();

        // reset page
        filterList.pagination = 1;

        // set page filtered so we don't exclude posts anymore
        filterList.filtered = true;

        filterList.activateClearFilters();
        filterList.handleAJAX();
    });

    $element.find('.cu-section__filter-bar .clear-filters').on('click', function(e) {
        e.preventDefault();

        // clear filters
        $element.find('.cu-section__filter-bar .filter-list input[type="checkbox"]:checked, .cu-section__filter-bar .inline-filter input[type="radio"]').prop('checked', false);
        filterList.filters = {};
        filterList.createPageFiltersObject();

        // clear search
        filterList.search = '';
        $element.find('#filter-search-value').val('');

        // hide filter box
        $element.find('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
        $element.find('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

        // reset page
        filterList.pagination = 1;

        filterList.activateClearFilters();
        filterList.createActiveFilters();
        filterList.handleAJAX();
    });

    $element.find('.filter-list-more .view-more').on('click', function(e) {
        filterList.pagination++;

        if (typeof $(this).data('excluded') !== 'undefined' && $(this).data('excluded') !== 'false' && $(this).data('excluded') !== false) {
            filterList.excludedPosts = $(this).data('excluded');
        }

        filterList.handleAJAX();
    });

    ///////////// HANDLE MOBILE VERSION OF FILTER BAR /////////////
    $element.find('.cu-section__mobile-filter-bar .mobile-filter-overlay-toggle').on('click', function(e) {
        e.preventDefault();

        $element.find('.cu-section__filter-bar-overlay').show().focus();
    });

    $element.find('.cu-section__filter-bar-overlay .close-overlay').on('click', function(e) {
        e.preventDefault();

        $element.find('.cu-section__filter-bar-overlay').hide();
        $element.find('.cu-section__mobile-filter-bar').focus();
    });

    $element.find('.cu-section__filter-bar-overlay .mobile-filter-list-toggle').on('click', function(e) {
        e.preventDefault();

        if ($(this).hasClass('active')) {
            $element.find('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();
        } else {
            $element.find('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();
            $(this).addClass('active').siblings('ul').show();
        }
    });

    $element.find('.cu-section__filter-bar-overlay .apply-mobile-filters').on('click', function(e) {
        e.preventDefault();

        let clickedTaxonomy = $(this).data('taxonomy');

        // reset taxonomy current filters and redo them based on selections
        filterList.filters[clickedTaxonomy] = {};

        $element.find('.cu-section__filter-bar-overlay input[type="checkbox"]:checked').each(function() {
            let inputTaxonomy = $(this).attr('name');
            let inputTerm = $(this).val();
            let inputText = $(this).data('term-name');

            filterList.filters[inputTaxonomy][inputTerm] = inputText;
            
            // Update desktop checkboxes
            $element.find(`#${inputTaxonomy} input[id="${inputTaxonomy}-${inputTerm}"]`).prop('checked', true);
        });

        // Uncheck desktop checkboxes that are not checked in mobile
        $element.find('.cu-section__filter-bar .filter-list input[type="checkbox"]').each(function() {
            let desktopTaxonomy = $(this).attr('name');
            let desktopTerm = $(this).val();
            if (!$element.find(`.cu-section__filter-bar-overlay input[id="mobile-${desktopTaxonomy}-${desktopTerm}"]`).prop('checked')) {
                $(this).prop('checked', false);
            }
        });

        // hide filter
        $element.find('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();

        // hide overlay
        $element.find('.cu-section__filter-bar-overlay').hide();

        // reset page
        filterList.pagination = 1;

        // set page filtered so we don't exclude posts anymore
        filterList.filtered = true;

        filterList.createMobileActiveFilters();
        filterList.createActiveFilters(); // Update desktop active filters
        filterList.handleAJAX();
    });

    $element.find('.clear-mobile-filters').on('click', function(e) {
        e.preventDefault();

        // clear filters
        $element.find('.cu-section__filter-bar-overlay input[type="checkbox"]:checked').prop('checked', false);
        filterList.filters = {};
        filterList.createPageFiltersObject();

        // clear search
        filterList.search = '';
        $element.find('#mobile-filter-search-value').val('');

        // hide overlay
        $element.find('.cu-section__filter-bar-overlay').hide();

        // reset page
        filterList.pagination = 1;

        filterList.createMobileActiveFilters();
        filterList.handleAJAX();
    });

    $element.find('.cu-section__mobile-filter-bar .search').on('submit', function(e) {
        e.preventDefault();

        filterList.search = $element.find('#mobile-filter-search-value').val();

        // reset page
        filterList.pagination = 1;

        // set page filtered so we don't exclude posts anymore
        filterList.filtered = true;
        
        filterList.handleAJAX();
    });

    $element.find('.cu-section__filter-bar .filter-list input[type="checkbox"]').on('change', function() {
        let clickedTaxonomy = $(this).attr('name');
        let clickedTerm = $(this).val();
        let isChecked = $(this).prop('checked');

        // Update mobile overlay checkbox
        filterList.element.find(`.cu-section__filter-bar-overlay input[id="mobile-${clickedTaxonomy}-${clickedTerm}"]`).prop('checked', isChecked);

        // You might want to update the filters object here as well
        if (isChecked) {
            if (!filterList.filters[clickedTaxonomy]) {
                filterList.filters[clickedTaxonomy] = {};
            }
            filterList.filters[clickedTaxonomy][clickedTerm] = $(this).data('term-name');
        } else {
            delete filterList.filters[clickedTaxonomy][clickedTerm];
        }

        // You might want to trigger other updates here, like createActiveFilters() and handleAJAX()
    });
}

// Initialize all filter lists on the page
$('.cumulo-filter-list').each((_, el) => {
    const filterList = new FilterList(el);
    handleFilterPage(filterList);
});