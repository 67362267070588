import $ from 'jquery';
import throttle from '../global/throttle.js';

function tbxHandleExploreNav() {
	if ( $('.cu-section__explore-nav').length > 0 ) {
		// handle scroll
		$(window).on('load scroll', throttle( function() {
			let navTop = $('.cu-section__explore-nav-placeholder').position().top;
			let windowTop = $(window).scrollTop();

			if ( windowTop + 107 > navTop ) {
				$('.cu-section__explore-nav-placeholder').addClass('active');
				$('.cu-section__explore-nav').addClass('fixed');
			}
			else {
				$('.cu-section__explore-nav-placeholder').removeClass('active');
				$('.cu-section__explore-nav').removeClass('fixed');
			}
		}, 100 ));

		// handle mobile explore nav navigation
		$('.cu-section__explore-nav .mobile-explore-nav').on('change', function(e) {
			window.location = $(this).find('option:selected').val();
		});
	}
}

export default tbxHandleExploreNav;