// File: blogs.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleFilterPage from '../sections/filter-list.js';

class BlogsRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

		tbxHandleFilterPage();
    }
}

// Don`t forget to export your renderer
export default BlogsRenderer;