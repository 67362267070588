import $ from 'jquery';
import jscookie from 'jscookie';

function tbxHandleNotices() {
	$('.close-notice').on('click', function(e) {
		e.preventDefault();

		// check persistance
		var persistance = $(this).parents('.tbx-module__notice').data('persistance');
		if ( persistance == 'session' )
		{
			jscookie.set({
				name: 'tbx_notice',
			    value: JSON.stringify(cookieOptions),
			    path: '/'
			});
		}
		else if ( persistance == 'cookie' )
		{
			var cookie = $(this).parents('.tbx-module__notice').data('cookie');
			if ( cookie == 'week' ) {
				jscookie.set({
					name: 'tbx_notice',
					value: JSON.stringify(cookieOptions),
					exdays: 7,
					path: '/'
				});
			}
			else if ( cookie == 'month' ) {
				jscookie.set({
					name: 'tbx_notice',
					value: JSON.stringify(cookieOptions),
					exdays: 30,
					path: '/'
				});
			}
			else if ( cookie == 'year' ) {
				jscookie.set({
					name: 'tbx_notice',
					value: JSON.stringify(cookieOptions),
					exdays: 365,
					path: '/'
				});
			}
		}

		$('html').removeClass('notice-shown');
		$('.tbx-module__notice').slideUp(200);
	});
}

export default tbxHandleNotices;