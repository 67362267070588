// File: form-template-1.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class FormTemplate1Renderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        const scripts = document.querySelectorAll('script');

        for ( const script of scripts ) {
            let code = script.innerText;

            if ( code.length !== 0 && ( code.indexOf('gravityform') !== -1 || code.indexOf('gform') !== -1 ) ) {
                Function(code)();
            }
        }

        $('#wpforms-37187 .wpforms-submit').on('click',function(){
            gtag('event', 'Submit (Long)', {
                'event_category': 'Lead Form (Long)',
                'event_label':'Academic Program',
                'value': 1
            });
            // ga('send', {
            //     hitType: 'event',
            //     eventCategory: 'Lead Form (Long)',
            //     eventAction: 'Submit',
            //     eventLabel: 'Academic Program'
            //   });
            console.log('Submit - Lead Form (Long)');
        });
    }
}

// Don`t forget to export your renderer
export default FormTemplate1Renderer;