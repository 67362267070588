// File: rentable-venues.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use( [ Navigation, Pagination ] );
import $ from "jquery";

class RentableVenuesRenderer extends Cumulo {
    onEnter() {
		// handle gallery swiper
        const rentableVenuesImageSwiper = new Swiper('.image-swiper', {
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            navigation: {
                nextEl: '.image-swiper-next',
                prevEl: '.image-swiper-prev'
            },
            pagination: {
                el: '.image-swiper-pagination'
            }
        });
    }
}

// Don`t forget to export your renderer
export default RentableVenuesRenderer;