// File: general-template-8.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';

class GeneralTemplate8Renderer extends Cumulo {
    onEnter() {
    }
}

// Don`t forget to export your renderer
export default GeneralTemplate8Renderer;