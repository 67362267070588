// File: next-steps-ind.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleExpanders from '../sections/expanders.js';

class NextStepsIndRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        // handle jump nav
        $('.jump-nav .toggle').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('.jump-nav .nav').toggle();
        });

        $('body').on('click', function() {
            $('.jump-nav .nav').hide();
        });

        $('.jump-nav .nav li').on('click', function(e) {
            e.stopPropagation();
            let text = $(this).text();
            let value = $(this).data('option-value');

            $('.jump-nav .toggle').text(text);

            $('.jump-nav .nav').hide();

            if ( value.includes('#') ) {
                e.preventDefault();
                $('html, body').animate({
                    scrollTop: $(value).offset().top - 130
                }, 800);
            }
            else {
                window.location.href = value;
            }
        });

        $('.jump-nav .mobile-toggle').on('change', function(e) {
            let value = $(this).find('option:selected').val();

            if ( value.includes('#') ) {
                $('html, body').animate({
                    scrollTop: $(value).offset().top - 110
                }, 800);
            }
            else {
                window.location.href = value;
            }
        });

        tbxHandleExpanders();
    }
}

// Don`t forget to export your renderer
export default NextStepsIndRenderer;