// File: next-steps.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';

class NextStepsRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        $('.side-jump-nav a').on('click', function(e) {
            e.preventDefault();

            $('html, body').animate({
                scrollTop: $( $.attr(this, 'href') ).offset().top - 130
            }, 800);
        });
        
        $('.jump-nav .mobile-toggle').on('change', function(e) {
            let value = $(this).find('option:selected').val();

            $('html, body').animate({
                scrollTop: $('#'+value).offset().top - 110
            }, 800);
        });
    }
}

// Don`t forget to export your renderer
export default NextStepsRenderer;