// File: seminary-landing.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import tbxHandleNewsFeed from '../sections/news-feed.js';

class SeminaryLandingRenderer extends Cumulo {
    onEnter() {
        tbxHandleNewsFeed();
    }
}

// Don`t forget to export your renderer
export default SeminaryLandingRenderer;