/*------------------------------------------
	SCROLL TRIGGERED ANIMATION
============================================*/
import $ from "jquery";

export function tbxIsScrolledIntoView(element) {
	const $elem = $(element);

    if ( $elem.length > 0 )
    {
    	let $window = $(window);
	    let scroll_trigger_point = $window.height() * 0.8;
	    const elemTop = $elem.offset().top;
	    let scrollPos = $window.scrollTop() + scroll_trigger_point;

		if ( scrollPos >= elemTop )
		{
			$elem.addClass('tbx-animated-siv tbx-animated-siv-ft').trigger('tbxClassChange');
			return true;
		}
		else
		{
			$elem.removeClass('tbx-animated-siv');
			return false;
		}
	}
	else
	{
		$elem.removeClass('tbx-animated-siv');
		return false;
	}
}

function tbxHandleScrollLoadEvent() {
	$('.tbx-animated').each(function() {
		tbxIsScrolledIntoView($(this));
	});
}

export default tbxHandleScrollLoadEvent;