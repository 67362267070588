import $ from "jquery";
import Isotope from "isotope-layout";

let pageFiltered = false;
let excludedPosts = '';
let pageFilters = {};
let pageSearch = '';
let pagePagination = 1;
let pageGridEl = document.querySelector('.cu-section__filter-list.table .table-body') || document.querySelector('.cu-section__filter-list');
let pageGrid = null;
if ( pageGridEl !== null ) {
	if ( pageGridEl.parentNode.parentNode.classList.contains('table') ) {
		pageGrid = new Isotope( pageGridEl, {
		itemSelector: '.filter-list-item',
			layoutMode: 'vertical'
		} );
	} else {
		pageGrid = new Isotope( pageGridEl, {
			itemSelector: '.filter-list-item',
			layoutMode: 'fitRows',
			fitRows: {
				gutter: 30
			}
		} );
	}
}

function handleAJAX() {
	if ( pageFiltered ) {
		excludedPosts = '';
	}

	const $filterList = $('.cu-section__filter-list');
	const filterListDegreeType = $filterList.data('degree-type');
	const filterListLocation = $filterList.data('location');

	const data = {
		action: 'tbx_handle_page_filter',
		filter_list: $filterList.data('filter-list'),
		is_table: $filterList.hasClass('table'),
		filters: JSON.stringify( pageFilters ),
		search: pageSearch,
		paged: pagePagination,
		excluded: excludedPosts
	};

	if ( typeof filterListDegreeType !== typeof undefined && filterListDegreeType !== false ) {
		data.degree_type = filterListDegreeType;
	}

	if ( typeof filterListLocation !== typeof undefined && filterListLocation !== false ) {
		data.location = filterListLocation;
	}

	//console.log(data);
	$.ajax({
		url: tbx_ajax_url,
		data: data,
		success: function( response, status, request ) {
			//console.log(response);
			$('.no-posts').remove();
			$('.view-more').removeClass('disabled');

			if ( pagePagination === 1 ) {
				pageGrid.remove( pageGrid.getItemElements() );
			}

			//let $container = $('.cu-section__filter-list.table .table-body') || $('.cu-section__filter-list');
			let $container = $('.cu-section__filter-list');
			if ( $('.cu-section__filter-list.table .table-body').length )
			{
				$container = $('.cu-section__filter-list.table .table-body');
			}

			if ( response.indexOf('no-posts') >= 0 ) {
				$container.append(response);
				pageGrid.layout();
				$container.css('height', 'auto');
			}
			else {
				let $items = $(response);
				$container.append($items);
				pageGrid.appended($items);
				pageGrid.layout();
			}

			// Check if last page
            let $elements = pageGrid.getItemElements();
            if ( $elements.length > 0 ) {
            	if ( $elements[$elements.length - 1].classList.contains('end') ) {
	                $('.view-more').addClass('disabled').text('No More Posts');
	            }
            }
		},
		error: function( request, status, error ) {
			console.log(error);
		}
	})
}

function attachRemoveFilterEvents() {
	$('.remove-active-filter').on('click', function(e) {
		e.preventDefault();

		const clickedTermSlug = $(this).data('filter-term');
		const clickedTaxonomy = $(this).data('filter-taxonomy');

		// remove active class
		$(`#${clickedTaxonomy} input[id="${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
		
		// remove from page filters
		delete pageFilters[clickedTaxonomy][clickedTermSlug];

		createActiveFilters();
		activateClearFilters();
		handleAJAX();
	});

	if ( ! $('.cu-section__filter-bar .active-filters').is(':empty') ) {
		$('.cu-section__filter-bar .active-filters').removeClass('hidden');
	}
	else {
		$('.cu-section__filter-bar .active-filters').addClass('hidden');
	}
}

function attachMobileRemoveFilterEvents() {
	$('.remove-active-filter').on('click', function(e) {
		e.preventDefault();

		const clickedTermSlug = $(this).data('filter-term');
		const clickedTaxonomy = $(this).data('filter-taxonomy');

		// remove active class
		$(`.cu-section__filter-bar-overlay input[id="mobile-${clickedTaxonomy}-${clickedTermSlug}"]`).prop('checked', false);
		
		// remove from page filters
		delete pageFilters[clickedTaxonomy][clickedTermSlug];

		createMobileActiveFilters();
		handleAJAX();
	});

	if ( ! $('.cu-section__mobile-filter-bar .active-filters').is(':empty') ) {
		$('.cu-section__mobile-filter-bar .active-filters-wrap').removeClass('hidden');
	}
	else {
		$('.cu-section__mobile-filter-bar .active-filters-wrap').addClass('hidden');
	}
}

function createActiveFilters() {
	// remove current filters
	$('.cu-section__filter-bar .active-filters').html('');

	// loop over filters
	const filterEntries = Object.entries( pageFilters );

	for ( const [ filter, terms ] of filterEntries ) {
		if ( filter !== 'buckets' && filter !== 'blog-bucket' )
		{
			// loop over terms within that filter
			const termEntries = Object.entries( terms );

			for ( const [ slug, name ] of termEntries ) {
				// create the active filter markup
				$('.cu-section__filter-bar .active-filters').append(`<button type="button" class="remove-active-filter" data-filter-taxonomy="${filter}" data-filter-term="${slug}">${name}</button>`);
			}
		}
	}

	attachRemoveFilterEvents();
}

function createMobileActiveFilters() {
	// remove current filters
	$('.cu-section__mobile-filter-bar .active-filters').html('');

	// loop over filters
	const filterEntries = Object.entries( pageFilters );

	for ( const [ filter, terms ] of filterEntries ) {
		if ( filter !== 'buckets' && filter !== 'blog-bucket' )
		{
			// loop over terms within that filter
			const termEntries = Object.entries( terms );

			for ( const [ slug, name ] of termEntries ) {
				// create the active filter markup
				$('.cu-section__mobile-filter-bar .active-filters').append(`<button type="button" class="remove-active-filter" data-filter-taxonomy="${filter}" data-filter-term="${slug}">${name}</button>`);
			}
		}
	}

	attachMobileRemoveFilterEvents();
}

function activateClearFilters() {
	// need to check if we should actually active this first
	if ( ( pageSearch !== '' && pageSearch !== 'SEARCH...' ) || $('.cu-section__filter-bar .filter-list input[type="checkbox"]:checked').length !== 0 ) {
		$('.cu-section__filter-bar .clear-filters').removeClass('hidden');
	}
	else {
		$('.cu-section__filter-bar .clear-filters').addClass('hidden');
	}
}

function createPageFiltersObject() {
	// handle page bucket
	// we also don't want to show this as a filter option so this is hidden when creating active filters
	let pageFilterList = $('.cu-section__filter-list').data('filter-list');
	let pageBucketSlug = $('.cu-section__filter-list').data('filter-bucket-slug');
	let pageBucketName = $('.cu-section__filter-list').data('filter-bucket-name');
	if ( pageBucketSlug !== '' && pageBucketName !== '' ) {
		if ( pageFilterList === 'blog' ) {
			pageFilters['blog-bucket'] = {};
			pageFilters['blog-bucket'][pageBucketSlug] = pageBucketName;
		}
		else {
			pageFilters['buckets'] = {};
			pageFilters['buckets'][pageBucketSlug] = pageBucketName;
		}
	}
	
	$('.cu-section__filter-bar .filter, .cu-section__filter-bar .inline-filter').each(function() {
		let taxonomy = $(this).data('taxonomy');
		
		pageFilters[taxonomy] = {};
	});
}

function tbxHandleFilterPage() {
	pageGridEl = document.querySelector('.cu-section__filter-list.table .table-body') || document.querySelector('.cu-section__filter-list');
	//console.log(pageGridEl);
	if ( pageGridEl !== null ) {
		if ( pageGridEl.parentNode.parentNode.classList.contains('table') ) {
			pageGrid = new Isotope( pageGridEl, {
			itemSelector: '.filter-list-item',
				layoutMode: 'vertical'
			} );
		} else {
			pageGrid = new Isotope( pageGridEl, {
				itemSelector: '.filter-list-item',
				layoutMode: 'fitRows',
				fitRows: {
					gutter: 30
				}
			} );
		}
	}

	// get all taxonomies on load
	createPageFiltersObject();

	// handle inline filter click
	$('.cu-section__filter-bar .inline-filter input[type="radio"]').on('change', function(e) {
		e.preventDefault();

		let clickedFilter = $(this).attr('name');
		let inputTerm = $(this).val();
		let inputText = $(this).data('term-name');

		pageFilters[clickedFilter] = {};

		pageFilters[clickedFilter][inputTerm] = inputText;

		// hide filter box
		$('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
		$('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

		// reset page
		pagePagination = 1;

		// set page filtered so we don't exclude posts anymore
		pageFiltered = true;

		createActiveFilters();
		activateClearFilters();
		handleAJAX();
	});

	// show filter overlay on filter toggle click
	$('.cu-section__filter-bar .filter-toggle').on('click', function(e) {
		e.preventDefault();
		e.stopPropagation();

		let clickedFilter = $(this).parent().data('taxonomy');

		if ( $(this).hasClass('active') ) {
			$(this).removeClass('active');
			$('#'+clickedFilter).removeClass('shown').hide();
		}
		else {
			$('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
			$('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();
			$(this).addClass('active');
			$('#'+clickedFilter).addClass('shown').show();
		}
	});

	// handle filter button click
	$('.cu-section__filter-bar .filter-selections').on('click', function(e) {
		e.preventDefault();

		let clickedTaxonomy = $(this).data('taxonomy');

		// reset taxonomy current filters and redo them based on selections
		pageFilters[clickedTaxonomy] = {};

		$(`input[name="${clickedTaxonomy}"]:checked`).each(function() {
			let inputTerm = $(this).val();
			let inputText = $(this).data('term-name');

			pageFilters[clickedTaxonomy][inputTerm] = inputText;
		});

		// hide filter box
		$('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
		$('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

		// reset page
		pagePagination = 1;

		// set page filtered so we don't exclude posts anymore
		pageFiltered = true;

		createActiveFilters();
		activateClearFilters();
		handleAJAX();
	});

	$('.cu-section__filter-bar .search').on('submit', function(e) {
		e.preventDefault();

		pageSearch = $('#filter-search-value').val();

		// reset page
		pagePagination = 1;

		// set page filtered so we don't exclude posts anymore
		pageFiltered = true;

		activateClearFilters();
		handleAJAX();
	});

	$('.cu-section__filter-bar .clear-filters').on('click', function(e) {
		e.preventDefault();

		// clear filters
		$('.cu-section__filter-bar .filter-list input[type="checkbox"]:checked, .cu-section__filter-bar .inline-filter input[type="radio"]').prop('checked', false);
		pageFilters = {};
		createPageFiltersObject();

		// clear search
		pageSearch = '';
		$('#filter-search-value').val('');

		// hide filter box
		$('.cu-section__filter-bar .filter-toggle.active').removeClass('active');
		$('.cu-section__filter-bar .filter-list.shown').removeClass('shown').hide();

		// reset page
		pagePagination = 1;

		activateClearFilters();
		createActiveFilters();
		handleAJAX();
	});

	$('.filter-list-more .view-more').on('click', function(e) {
		pagePagination++;

		if ( typeof $(this).data('excluded') !== 'undefined' && $(this).data('excluded') !== 'false' && $(this).data('excluded') !== false ) {
			excludedPosts = $(this).data('excluded');
		}

		handleAJAX();
	});

	///////////// HANDLE MOBILE VERSION OF FILTER BAR /////////////
	$('.cu-section__mobile-filter-bar .mobile-filter-overlay-toggle').on('click', function(e) {
		e.preventDefault();

		$('.cu-section__filter-bar-overlay').show().focus();
	});

	$('.cu-section__filter-bar-overlay .close-overlay').on('click', function(e) {
		e.preventDefault();

		$('.cu-section__filter-bar-overlay').hide();
		$('.cu-section__mobile-filter-bar').focus();
	});

	$('.cu-section__filter-bar-overlay .mobile-filter-list-toggle').on('click', function(e) {
		e.preventDefault();

		if ( $(this).hasClass('active') ) {
			$('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();
		}
		else {
			$('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();
			$(this).addClass('active').siblings('ul').show();
		}
	});

	$('.cu-section__filter-bar-overlay .apply-mobile-filters').on('click', function(e) {
		e.preventDefault();

		let clickedTaxonomy = $(this).data('taxonomy');

		// reset taxonomy current filters and redo them based on selections
		pageFilters[clickedTaxonomy] = {};

		$('.cu-section__filter-bar-overlay input[type="checkbox"]:checked').each(function() {
			let inputTaxonomy = $(this).attr('name');
			let inputTerm = $(this).val();
			let inputText = $(this).data('term-name');

			pageFilters[inputTaxonomy][inputTerm] = inputText;
		});

		// hide filter
		$('.cu-section__filter-bar-overlay .mobile-filter-list-toggle.active').removeClass('active').siblings('ul').hide();

		// hide overlay
		$('.cu-section__filter-bar-overlay').hide();

		// reset page
		pagePagination = 1;

		// set page filtered so we don't exclude posts anymore
		pageFiltered = true;

		createMobileActiveFilters();
		handleAJAX();
	});

	$('.clear-mobile-filters').on('click', function(e) {
		e.preventDefault();

		// clear filters
		$('.cu-section__filter-bar-overlay input[type="checkbox"]:checked').prop('checked', false);
		pageFilters = {};
		createPageFiltersObject();

		// clear search
		pageSearch = '';
		$('#mobile-filter-search-value').val('');

		// hide overlay
		$('.cu-section__filter-bar-overlay').hide();

		// reset page
		pagePagination = 1;

		createMobileActiveFilters();
		handleAJAX();
	});

	$('.cu-section__mobile-filter-bar .search').on('submit', function(e) {
		e.preventDefault();

		pageSearch = $('#mobile-filter-search-value').val();

		// reset page
		pagePagination = 1;

		// set page filtered so we don't exclude posts anymore
		pageFiltered = true;
		
		handleAJAX();
	});
}

export default tbxHandleFilterPage;