// File: hub-financial-aid.js
import Cumulo from '../modules/cumulo.js';
import tbxHandleExpanders from '../sections/expanders.js';

class HubFinancialAidRenderer extends Cumulo {
    onEnterCompleted() {
    	tbxHandleExpanders();
    }
}

export default HubFinancialAidRenderer;