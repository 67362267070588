// File: expand-collapse.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleExpanders from '../sections/expanders.js';

class ExpandCollapseRenderer extends Cumulo {
    onEnter() {
		$('html').addClass('alt-header');

        tbxHandleExpanders();
    }
}

// Don`t forget to export your renderer
export default ExpandCollapseRenderer;