// File: faculty-ind.js
// Import Cumulo
import Cumulo from '../modules/cumulo.js';
import $ from 'jquery';
import tbxHandleNewsFeed from '../sections/news-feed.js';

class FacultyIndRenderer extends Cumulo {
    onEnter() {
        $('html').addClass('alt-header');
        tbxHandleNewsFeed();
    }
}

// Don`t forget to export your renderer
export default FacultyIndRenderer;